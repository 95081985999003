import { createStore } from 'vuex'

export default createStore({
	state: {
		isLogin:false,
		userStore:{}
	},
	getters: {
		getUserLoginStatus:(state)=>{
			return state;
		}
	},
	mutations: {
	},
	actions: {
	},
	modules: {
	}
})
