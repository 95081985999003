import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible'
import 'vant/lib/index.css';
import '@/assets/fonts/iconfont.css';

//全局路由拦击
router.beforeEach((to, from, next) => {
	//判断是否微信登录
	if (to.meta.requiresAuth && (!localStorage.getItem('token') || localStorage.getItem('token') == 'null' || localStorage.getItem('userInfo') == 'null')) {
		sessionStorage.setItem('userfullPath',to.fullPath)
		next({path: '/logins'});
	}else{
		next();
	}
})
const app=createApp(App)
//阻止启动时生成生产提示
//app.config.productionTip = false;
app.use(store).use(router).mount('#app')
