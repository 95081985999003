<template>
  <router-view/>
</template>

<style lang="less">
html,body{background-color: #f5f5f5; padding: 0; margin: 0;}
body{font-size:.4rem !important;}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
*{box-sizing: border-box;}
a{color: #555555; text-decoration: none;}
.link-color{color: #063bfe;}
h1,h2,h3,h4,h5,h6,p,dl,dt,dd{padding: 0;margin: 0;}
.page-container{padding: .26654rem;}
img{display:block;max-width: 100%;}
.flex{display:flex;}
#audioplays{display:none;}
</style>
